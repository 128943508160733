



























import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import Groups from "@/components/GroupPlanTable.vue";
import GroupPlanTable from "@/components/GroupPlanTable.vue";
import {User} from "@/api";
import GroupTable from "@/components/GroupTable.vue";

const Auth = namespace("Auth");
@Component({
  components: {GroupPlanTable, GroupTable, Groups}
})
export default class Group extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  }
}
